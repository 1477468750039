const testing = {
  isProduction: false,
  port: process.env.PORT || 5005,
  API_URL_GOBLIN: `http://f1-goblin:8000/api/v2`,
  API_URL_GOBLIN_CDN: `http://f1-goblin:8000/api/v2`,
  VIEW_URL_GOBLIN: `http://f1-goblin:8000`,

  API_URL_MEDIA_CDN: 'https://media.dekoruma.com',

  API_URL_4:
    'https://wp2-dekoruma.global.ssl.fastly.net/rest/v1.1/sites/dekoruma.wordpress.com',
  API_URL_5: `http://f1-cuckoos:8001`,
  // f1-cuckoos
  API_URL_MADTHINKER: `http://f1-madthinker:4000/api/v1`,
  API_URL_7: `https://f1-collector:8007/api/v1`,
  // f1-collector
  API_URL_DORMAMMU: `http://f1-dormammu:8005/api/v1`,
  API_URL_THANOS: `http://f1-thanos:8013/api/v1`,
  API_URL_GALACTUS: `http://f1-galactus:8002`,
  API_URL_SIN: `http://f1-sin:1710/api/v1`,
  API_URL_ULTRON: 'http://192.168.43.205:3220/api/v1',
  API_KEY_SIN: 'tUrf@-G4n7en6_w3-f4ll-1n_L0v3-w1Th_s0m30n3-W3_c4n-N07_h@V3',
  API_URL_CHITAURI: `http://f1-chitauri:6900/api/v1`,
  API_URL_RONAN: `http://f1-ronan:8040/api/v1`,
  API_KEY_CHITAURI: 'ywbi&nvxph()q^j+!$2^cdh5)qzg8dq6em4@jcxla3g8k#2njw',
  API_URL_KINGPIN: `http://f1-kingpin:9000/api/v1`,
  API_URL_VULTURE: `http://f1-vulture:7000/api/v1`,
  API_URL_HELA: `http://f1-hela:4200/api/v1`,
  API_URL_KLAW: `http://f1-klaw:8011/api/v1`,
  API_URL_SURTUR: `http://f1-surtur:8008/api/v1`,
  API_URL_SURTUR_V2: `http://f1-surtur:8008/api/v2`,
  API_URL_MAXIMUS: `http://f1-maximus:8024/api/v1`,
  API_URL_EGO: `http://f1-ego:8800/api/v1`,
  API_URL_MANDARIN: `http://f1-mandarin:6001/api/v1`,
  API_URL_BULLSEYE: 'https://us-central1-f1-bullseye.cloudfunctions.net',
  API_URL_DOOM: `http://f1-doom:8080/api/v1`,
  API_URL_NOTIF: 'http://f1-notif:6002/api',
  ALGOLIA_DEFAULT_INDEX_NAME: 'staging_products',
  ALGOLIA_AREA_INDEX_NAME: 'master_locations',
  ALGOLIA_ORDER_INDEX_NAME: 'master_orders',
  ALGOLIA_MOODBOARD_INDEX_NAME: 'staging_moodboards',
  ALGOLIA_HOTSPOT_INDEX_NAME: 'staging_hotspots',
  ALGOLIA_BUSINESS_PRODUCT_AGGREGATOR_INDEX_NAME:
    'staging_b2b_product_aggregated',
  ALGOLIA_FEED_INDEX_NAME: 'staging_feeds',
  ALGOLIA_STOCK_RECORD_INDEX_NAME: 'staging_stockrecords',
  ALGOLIA_PHOTO_RECORD_INDEX_NAME: 'staging_photorecords',
  ALGOLIA_PRODUCT_OPTION_VALUE_INDEX_NAME: 'staging_product_options',
  ALGOLIA_COMPLAIN_INDEX_NAME: 'staging_complains',
  ALGOLIA_PROJECT_TEMPLATE_INDEX_NAME: 'staging_project_templates',
  ALGOLIA_ALBUM_INDEX_NAME: 'master_albums',
  ALGOLIA_USER_INDEX_NAME: 'staging_users',
  ALGOLIA_BLACKMARKET_PRODUCT_INDEX_NAME: 'staging_chitauri_products',
  ALGOLIA_ARTICLE_SEARCH_INDEX_NAME: 'wp_searchable_posts',
  ALGOLIA_PROJECT_INDEX_NAME: 'staging_projects',
  ALGOLIA_PORTFOLIO_INDEX_NAME: 'staging_portfolios',
  ALGOLIA_PRODUCT_SUGGESTION_INDEX_NAME: 'query_suggestion_v3',
  ALGOLIA_PRODUCT_DISTINCT_CATEGORY_INDEX_NAME: 'distinct_categories',
  ALGOLIA_SUPPLY_WAREHOUSE_INDEX_NAME: 'supply_warehouse',
  ALGOLIA_REVIEW_INDEX_NAME: 'staging_reviews',
  ALGOLIA_UNIT_TYPE_INDEX_NAME: 'staging_unit_types',
  ALGOLIA_REAL_ESTATE_INDEX_NAME: 'staging_real_estates',
  ALGOLIA_BOOKING_INDEX_NAME: 'staging_bookings',
  ALGOLIA_AD_SLOT_INDEX_NAME: 'staging_ad_slots',
  ALGOLIA_BANK_INDEX_NAME: 'staging_banks',
  ALGOLIA_3D_ASSET_INDEX_NAME: 'staging_3d_assets',
  ALGOLIA_3D_ASSET_MOST_DOWNLOADED_INDEX_NAME:
    'staging_3d_assets_most_downloaded',
  ALGOLIA_3D_ASSET_RECENTLY_UPDATED_INDEX_NAME:
    'staging_3d_assets_recently_updated',
  ALGOLIA_INVESTMENT_INDEX_NAME: 'staging_investments',
  ALGOLIA_DESIGNER_INDEX_NAME: 'staging_designers',
  ALGOLIA_VOUCHER_INDEX_NAME: 'staging_vouchers',
  ALGOLIA_FLASH_SALE_INDEX_NAME: 'staging_flash_sales',
  // S3_SIGN_URL: 'http://' + localhost + ':3000/api/s3/sign', // f1-venom
  // the following S3_SIGN_URL is set up with environment variable that allows localhost access.
  S3_SIGN_URL: 'http://f1-sign-y361678b056b7745.herokuapp.com/api/s3/sign',
  S3_BUCKET_PUBLIC_URL: 'https://f1-static.s3-ap-southeast-1.amazonaws.com',
  S3_BUCKET_PRIVATE_URL: 'https://s3-ap-southeast-1.amazonaws.com/f1-static',
  // https://s3-ap-southeast-1.amazonaws.com/f1-static
  // CDN_S3: '//de19nmb9dniyb.cloudfront.net', // towards `S3_BUCKET_PUBLIC_URL`
  CDN_SELF: '',
  SOMA_URL: 'http://f1-loki-soma:5070',

  BLIBLI_TIKET_ACCOUNT_CENTER_URL: 'https://staging.bliblitiket.com',
  BLIBLI_TIKET_REWARDS_HOMEPAGE_URL:
    'https://bliblitiketrewards-dev1.gdn-app.com',
  BLIBLI_TIKET_REWARDS_VOUCHER_DEALS_URL:
    'https://bliblitiketrewards-dev1.gdn-app.com/member/voucher-deals',
  ENABLE_ULP_FEATURES: false,

  // towards `f1-loki.herokuapp.com`
  FACEBOOK_APP_ID: 1112371492108485,
  FACEBOOK_SDK_URL:
    'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=1112371492108485',
  VERITRANS_CLIENT_KEY: 'SB-Mid-client-JhjSrXHsaNA0NQ_t',
  VERITRANS_TOKEN_URL: 'https://api.sandbox.midtrans.com/v2/token',
  ALGOLIA_APP_NAME: 'D2VY06YP2A',
  ALGOLIA_API_KEY: '5bbda829365ba015eb6fcec10cccdf72',
  FIREBASE: {
    apiKey: 'AIzaSyB5RZ-k9Fd8RVCMJRbmOHK_32fwVWhHpIA',
    authDomain: 'f1-bullseye.firebaseapp.com',
    databaseURL: 'https://f1-bullseye.firebaseio.com',
    projectId: 'f1-bullseye',
    storageBucket: 'f1-bullseye.appspot.com',
    messagingSenderId: '588138102088',
    appId: '1:588138102088:web:57eea37640c43756',
  },
  SENTRY_DSN: 'https://79eb625a247046a8893095de327feb7c@sentry.dekoruma.com/4',
  GOOGLE_MAPS_API_URL:
    'https://maps.googleapis.com/maps/api/js?key=AIzaSyB5RZ-k9Fd8RVCMJRbmOHK_32fwVWhHpIA&v=3.exp&libraries=geometry,drawing,places',
};
module.exports = testing;
